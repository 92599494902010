<template>
  <v-container>
    <v-data-table v-bind="defaultAttrs" :options.sync="options">
      <template v-slot:item.hide_in_dropdown="{ item }">
        {{ item.hide_in_dropdown ? "Yes" : "No" }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from "pinia";
import dataTablePagination from "@/mixins/dataTablePagination";
import { useFacilities } from "@/store/facilities.pinia.js";

export default {
  name: "FacilitiesBrowse",
  mixins: [dataTablePagination],
  data() {
    return {
      activeItemId: null,
      defaultOptions: {
        sortBy: ["title"],
        sortDesc: [false],
      },
      headers: [
        // { text: "ID", value: "id" },
        { text: "Facility Name", value: "title" },
        { text: "FacilityCode", value: "code" },
        { text: "HideInDropdown", value: "hide_in_dropdown" },
      ],
    };
  },
  methods: {
    ...mapActions(useFacilities, ["getPaginated"]),
  },
};
</script>
