import Vue from "vue";
import debounce from "lodash.debounce";

export default {
  data() {
    return {
      items: [],
      dataMethod: "getPaginated",
      total: null,
      options: {
        itemsPerPage: 15,
        params: {},
      },
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Status", value: "active" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    defaultAttrs: {
      get() {
        return {
          items: this.items,
          headers: this.headers,
          loading: this.loading,
          "footer-props": { "items-per-page-options": [5, 15, 30, 50, 100] },
          "server-items-length": this.total,
        };
      },
    },
  },
  mounted() {
    if (this.defaultParams !== undefined) {
      for (let key in this.defaultParams) {
        Vue.set(this.options.params, key, this.defaultParams[key]);
      }
    }
    if (this.defaultOptions !== undefined) {
      for (let key in this.defaultOptions) {
        Vue.set(this.options, key, this.defaultOptions[key]);
      }
    }
    this.getPaginatedProxy();
  },
  methods: {
    getPaginated() {
      return new Promise((resolve) => {
        console.error(
          `Set up the ${this.dataMethod}() method, or configure the dataMethod property`
        );
        resolve({});
      });
    },
    getPaginatedProxy() {
      this.loading = true;
      this[this.dataMethod](this.options)
        .then((res) => {
          if (res?.data) {
            this.items = res.data;
            this.total = res.total;
          } else {
            console.error("No data in response", res);
          }
          this.loading = false;
        })
        .catch((e) => {
          console.error(e);
          this.loading = false;
        });
    },
    getPaginatedDebounced: debounce(function () {
      this.getPaginatedProxy();
    }, 200),
    refresh() {
      this.getPaginatedDebounced();
    },
  },
  watch: {
    options: {
      handler() {
        this.getPaginatedDebounced();
      },
      deep: true,
    },
    defaultParams: {
      handler(defaultParams) {
        if (defaultParams !== undefined) {
          for (let key in defaultParams) {
            Vue.set(this.options.params, key, defaultParams[key]);
          }
        }
        this.getPaginatedDebounced();
      },
      deep: true,
    },
  },
};
